@import '@/styles/variables';
@import '@/styles/breakpoints';

.headerContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 75vh;
  max-height: 100vh;

  .bgLine {
    background: url('/images/bg-lines-down.png') center center no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 220px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45rem;
    position: relative;
    margin-top: 0;
    gap: 0.5rem;

    @include responsive('sm') {
      height: 50rem;
      margin-top: 3rem;
      gap: 1.5rem;
    }

    .bookIconBorder {
      width: 10rem;
      height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(87, 66, 33, 0.1);
      box-shadow: 0 0 4px 2px $brown-shadow-50;
      position: relative;

      & .mouseFeatures {
        z-index: -3;
        padding: 0.35rem 0.75rem;
        background-color: $brown-shadow-40;
        color: $white-50;
        border-radius: 0.5rem;
        position: absolute;
        width: min-content;
        height: min-content;

        @include responsive('lg') {
          border-radius: 1rem;
        }

        &::after {
          content: '';
          background-size: contain;
          display: flex;
          width: 3rem;
          height: 3.24rem;
          position: absolute;
          top: -2rem;
        }

        &:nth-child(1) {
          top: 0;
          left: -4rem;

          @include responsive('sm') {
            left: -6rem;
          }

          @include responsive('md') {
            left: -9rem;
          }

          &::after {
            content: '';
            right: -1.7rem;
            background: url('/images/mouse-icon-right.svg') center center no-repeat;
          }
        }

        &:nth-child(2) {
          top: 6rem;
          left: -6rem;

          @include responsive('sm') {
            left: -10rem;
          }

          @include responsive('md') {
            left: -14rem;
          }

          &::after {
            content: '';
            right: -1.7rem;
            background: url('/images/mouse-icon-right.svg') center center no-repeat;
          }
        }

        &:nth-child(3) {
          top: 0;
          right: -5rem;

          @include responsive('sm') {
            right: -7rem;
          }

          @include responsive('md') {
            right: -10rem;
          }

          &::after {
            content: '';
            left: -1.7rem;
            background: url('/images/mouse-icon-left.svg') center center no-repeat;
          }
        }

        &:nth-child(4) {
          top: 6rem;
          right: -6rem;

          @include responsive('sm') {
            right: -10rem;
          }

          @include responsive('md') {
            right: -14rem;
          }

          &::after {
            content: '';
            left: -1.7rem;
            background: url('/images/mouse-icon-left.svg') center center no-repeat;
          }
        }
      }

      & > div {
        width: 8rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $brown-shadow-20;
        box-shadow:
          0 1px 3px 1px $brown-shadow-20,
          inset 0 1px 5px 1px $brown-shadow-90;
      }

      & > div > div {
        width: 6rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $brown-shadow-30;
        box-shadow:
          0 1px 3px 1px $brown-shadow-20,
          inset 0 1px 5px 1px $brown-shadow-90;

        .bookIcon {
          width: 4rem;
          height: auto;
          aspect-ratio: 1.41/1;
          position: relative;
          margin: auto;
        }
      }
    }

    & > h1 {
      font-size: 2rem;
      font-family: 'Segoe Print', sans-serif;
      font-weight: bold;
      letter-spacing: 3px;
      color: transparent;
      background: linear-gradient(90deg, rgba(255, 227, 202, 1) 0%, rgba(192, 156, 123, 1) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      margin-top: 3rem;

      @include responsive('lg') {
        font-size: 3rem;
      }
    }

    & > span {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 1.2rem;
      letter-spacing: 2px;
      margin-top: 1.5rem;
      text-align: center;

      @include responsive('sm') {
        font-size: 1.5rem;
      }

      @include responsive('lg') {
        font-size: 2rem;
      }
    }

    & > p {
      font-family: 'Inter', sans-serif;
      letter-spacing: 0.3px;
      line-height: 1.5;
      font-weight: 500;
      font-size: 0.85rem;
      text-align: center;
      color: $black-300;
    }

    & > a {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 0.85rem;
      color: $white-50;
      background-color: $orange-600;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      transition: background-color 0.2s linear;
      margin-top: 2rem;

      @include responsive('lg') {
        font-size: 1.1rem;
        padding: 1rem 2rem;
        margin-top: unset;
      }

      &:hover {
        background-color: $orange-800;
      }
    }
  }
}
