@import '@/styles/variables';
@import '@/styles/breakpoints';

.titleCardContainer {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;

  .titleCard {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    max-width: 100vw;
    border: 1px solid $gray-shadow-20;
    border-radius: 2rem;
    text-wrap: nowrap;
    overflow: hidden;
    padding: 0.35rem 0.75rem 0.35rem 1.35rem;
    font-size: 1rem;

    @include responsive('md') {
      padding: 0.5rem 1.2rem 0.5rem 2rem;
      font-size: 1.1rem;
    }
  }
}
