@import '@/styles/variables';
@import '@/styles/breakpoints';

.niafixBackground {
  background: url('/images/niafix-background.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  margin-top: 10rem;

  @include responsive('md') {
    background-size: contain;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    top: -4.5rem;

    @include responsive('md') {
      top: -6.5rem;
    }

    & > img {
      width: 130px;
      height: auto;

      @include responsive('md') {
        width: 140px;
      }

      @include responsive('lg') {
        width: 200px;
      }
    }

    & > p {
      font-family: 'Segoe UI', sans-serif;
      font-size: 1.4rem;
      color: $white-50;

      @include responsive('md') {
        font-size: 1.75rem;
      }
    }

    & > a {
      margin-top: 2rem;
      font-family: 'Segoe UI', sans-serif;
      font-size: 1.2rem;
      padding: 0.5rem 1rem;
      border-radius: 100px;
      border: 1px solid $black-300;
      background-color: $purple-shadow-10;
      letter-spacing: -0.3px;
      color: $black-200;
      gap: 0;
      transition: all 0.2s ease-in-out;

      @include responsive('lg') {
        font-size: 1.35rem;
        padding: 0.5rem 1.5rem;
        letter-spacing: -0.5px;
        gap: 0.25rem;
      }

      &:hover {
        background-color: $purple-shadow-20;

        & > img {
          transform: rotate(45deg);
        }
      }

      & > img {
        width: 2rem;
        height: auto;
        transition: all 0.2s linear;

        @include responsive('md') {
          width: 2.2rem;
          height: auto;
        }
      }
    }
  }
}
