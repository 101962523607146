@import '@/styles/variables';
@import '@/styles/breakpoints';

.bannerContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 45vh;
  overflow: hidden;
  border-bottom: 1px solid $gray-shadow-20;

  @include responsive('lg') {
    height: 85vh;
  }

  .banner {
    position: relative;
    width: 100%;
    height: 100%;

    .sliderItemBar {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: grid;
      grid-template-columns: 1.5fr 2fr 1fr;
      justify-content: space-around;
      align-items: center;
      gap: 5rem;
      background: rgba(0, 0, 0, 0.75);
      padding: 2rem;

      .sliderItemContent {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;

        h2 {
          font-size: large;
          font-weight: 700;
          letter-spacing: 0.05rem;
          text-wrap: nowrap;
          color: $white-50;
        }

        span {
          text-wrap: nowrap;
          letter-spacing: 0.05rem;
          text-transform: uppercase;
          color: $white-300;
        }

        strong {
          font-weight: 700;
          font-size: large;
          letter-spacing: 0.05rem;
          color: $orange-400;
        }
      }

      .sliderItemSummary > pre {
        font-size: 1.1rem;
        letter-spacing: 0.2px;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-wrap;
        -webkit-box-orient: vertical;
        font-family: 'Segoe UI', sans-serif;
      }

      .sliderItemActions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        a {
          padding: 0.7rem;
          border-radius: 50%;
          color: $white-50;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.1s linear;

          &:nth-child(1) {
            background-color: $pink-400;
            transform: rotateZ(45deg);

            &:hover {
              background-color: $pink-500;
            }
          }

          &:nth-child(2) {
            background-color: $orange-400;

            &:hover {
              background-color: $orange-500;
            }
          }

          &:nth-child(3) {
            background-color: $green-600;

            &:hover {
              background-color: $green-700;
            }
          }

          &:nth-child(4) {
            background-color: $red-300;

            &:hover {
              background-color: $red-400;
            }
          }
        }
      }
    }
  }
}
