@import 'variables';

@mixin responsive($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media screen and (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn 'No value found for breakpoint: #{$breakpoint}.';
  }
}

@mixin h-responsive($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media screen and (min-height: #{map-get($grid-breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn 'No value found for breakpoint: #{$breakpoint}.';
  }
}
