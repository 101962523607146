@import '@/styles/variables';
@import '@/styles/breakpoints';

.bgLine {
  background: url('/images/bg-lines.png') center center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 220px;
}

.navbar {
  height: 4.5rem;
  min-height: 4.5rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  & > div {
    position: relative;
    width: 175px;
    height: 100%;
  }

  & > ul {
    list-style: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include responsive('md') {
      gap: 1rem;
    }

    & > li {
      &:last-child {
        border-radius: 5rem;
        background: rgb(181, 155, 117);
        background: linear-gradient(
          90deg,
          rgba(181, 155, 117, 0.75) 0%,
          rgba(116, 93, 60, 0.75) 51%,
          rgba(103, 85, 53, 1) 100%
        );
      }

      & > a {
        font-family: 'Inter', sans-serif;
        color: $white-50;
        padding: 0.5rem 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 0.9rem;

        @include responsive('md') {
          font-size: 1.1rem;
        }

        &:hover {
          color: $white-400;
        }
      }
    }
  }
}
