/**
* Breakpoints
**/

$grid-breakpoints: (
  'xxs': '400px',
  'xs': '540px',
  'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'xl': '1280px',
  '2xl': '1536px',
);

$container-max-widths: (
  'xxs': calc(100% - 1rem),
  'xs': calc(100% - 2rem),
  'sm': 600px,
  'md': 720px,
  'lg': 960px,
  'xl': 1200px,
  '2xl': 1400px,
);

$gap-sizes: (
  xs: 0.25rem,
  sm: 0.5rem,
  md: 1rem,
  lg: 1.5rem,
  xl: 2rem,
  xxl: 2.5rem,
);

$flex-align-values: (
  center: center,
  flexStart: flex-start,
  flexEnd: flex-end,
  unset: unset,
);

$flex-justify-values: (
  center: center,
  flexStart: flex-start,
  flexEnd: flex-end,
  spaceBetween: space-between,
  spaceAround: space-around,
  spaceEvenly: space-evenly,
  unset: unset,
);

/**
* Colors
**/

$transparent: transparent;

$black-50: #e9e9e9;
$black-100: #d0d0d0;
$black-200: #b3b3b3;
$black-300: #a0a0a0;
$black-400: #808080;
$black-500: #666666;
$black-600: #303030;
$black-700: #1a1a1a;
$black-800: #0c0c0c;
$black-900: #050505;
$black-950: #000000;

$black-shadow-10: rgba(0, 0, 0, 0.1);
$black-shadow-20: rgba(0, 0, 0, 0.2);
$black-shadow-30: rgba(0, 0, 0, 0.3);
$black-shadow-40: rgba(0, 0, 0, 0.4);
$black-shadow-50: rgba(0, 0, 0, 0.5);
$black-shadow-60: rgba(0, 0, 0, 0.6);

$red-50: #fee2e2;
$red-100: #fca5a5;
$red-200: #f87171;
$red-300: #ef4444;
$red-400: #dc2626;
$red-500: #b91c1c;
$red-600: #9b1c1c;
$red-700: #7f1d1d;
$red-800: #641f1f;
$red-900: #3b0f0f;
$red-950: #4a0000;

$red-shadow-50: rgba(231, 66, 66, 0.5);
$red-shadow-60: rgba(231, 66, 66, 0.6);

$orange-50: #ffedd5;
$orange-100: #fef3c7;
$orange-200: #fcd34d;
$orange-300: #e39760;
$orange-400: #cc8b26;
$orange-500: #d97006;
$orange-600: #b45309;
$orange-700: #92400e;
$orange-800: #78350f;
$orange-900: #451a0d;
$orange-950: #3a0e0c;

$brown-50: #f9e9d0;
$brown-100: #f4e7df;
$brown-200: #ead0bd;
$brown-300: #d9b094;
$brown-400: #c18b65;
$brown-500: #a66f49;
$brown-600: #875735;
$brown-700: #684227;
$brown-800: #472e1a;
$brown-900: #281c10;

$brown-shadow-10: rgba(104, 66, 39, 0.1);
$brown-shadow-20: rgba(104, 66, 39, 0.2);
$brown-shadow-30: rgba(104, 66, 39, 0.3);
$brown-shadow-40: rgba(104, 66, 39, 0.4);
$brown-shadow-50: rgba(104, 66, 39, 0.5);
$brown-shadow-60: rgba(104, 66, 39, 0.6);
$brown-shadow-70: rgba(104, 66, 39, 0.7);
$brown-shadow-80: rgba(104, 66, 39, 0.8);
$brown-shadow-90: rgba(104, 66, 39, 0.9);

$orange-shadow-50: rgba(227, 151, 96, 0.5);
$orange-shadow-60: rgba(227, 151, 96, 0.6);

$blue-50: #dbeafe;
$blue-100: #bfdbfe;
$blue-200: #93c5fd;
$blue-300: #60a5fa;
$blue-400: #3b82f6;
$blue-500: #2563eb;
$blue-600: #1d4ed8;
$blue-700: #1e40af;
$blue-800: #1e3a8a;
$blue-900: #1e1a78;
$blue-950: #1d1b65;

$blue-shadow-50: rgba(98, 166, 217, 0.5);
$blue-shadow-60: rgba(98, 166, 217, 0.6);

$purple-50: #ede9fe;
$purple-100: #ddd6fe;
$purple-200: #c4b5fd;
$purple-300: #a78bfa;
$purple-400: #8b5cf6;
$purple-500: #7c3aed;
$purple-600: #6d28d9;
$purple-700: #5b21b6;
$purple-800: #4c1d95;
$purple-900: #3b0d7b;
$purple-950: #320f7d;

$pink-50: #fdf2f8;
$pink-100: #fce7f3;
$pink-200: #fbcfe8;
$pink-300: #f9a8d4;
$pink-400: #f472b6;
$pink-500: #ec4899;
$pink-600: #db2777;
$pink-700: #be185d;
$pink-800: #9d174d;
$pink-900: #831843;
$pink-950: #500724;

$purple-shadow-10: rgba(198, 155, 255, 0.1);
$purple-shadow-20: rgba(198, 155, 255, 0.2);
$purple-shadow-30: rgba(198, 155, 255, 0.3);
$purple-shadow-50: rgba(193, 124, 240, 0.5);
$purple-shadow-60: rgba(193, 124, 240, 0.6);

$white-50: #ffffff;
$white-100: #e2e6ea;
$white-200: #f3f4f6;
$white-300: #e5e7eb;
$white-400: #d1d5db;
$white-500: #9ca3af;
$white-600: #6b7280;
$white-700: #4b5563;
$white-800: #374151;
$white-900: #1f2937;

$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$gray-950: #0f172a;

$gray-shadow-20: rgba(179, 179, 179, 0.2);
$gray-shadow-60: rgba(179, 179, 179, 0.6);

$green-50: #f0fdf4;
$green-100: #dcfce7;
$green-200: #bbf7d0;
$green-300: #86efac;
$green-400: #4ade80;
$green-500: #22c55e;
$green-600: #16a34a;
$green-700: #15803d;
$green-800: #166534;
$green-900: #14532d;
$green-950: #052e16;

/**
* Layout
**

/**
* Font
**/
$baseFont: 'Segoe UI', 'sans-serif';
