@import '@/styles/variables';
@import '@/styles/breakpoints';

.imageCarousel {
  position: relative;
  width: 100%;
  aspect-ratio: 2 / 1;
  min-height: 200px;
  overflow: hidden;

  @include responsive('xxs') {
    aspect-ratio: 1 / 1;
  }

  @include responsive('md') {
    aspect-ratio: 16 / 9;
  }

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    filter: blur(10px);
  }

  img {
    object-fit: cover;
    width: 100%;
    object-position: center;
  }
}

.sliderItemBar {
  width: calc(100% - 2rem);
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  min-height: 12.5rem;
  gap: 1rem;
  margin: 3rem 1rem 2rem;

  @include responsive('md') {
    width: 100%;
    padding: 2rem;
    gap: 2rem;
    grid-template-columns: 1.5fr 2.5fr 1fr;
    position: absolute;
    bottom: 0;
    margin: 0;
  }

  .sliderItemContent {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: center;

    h2 {
      font-size: large;
      font-weight: 700;
      letter-spacing: 0.05rem;
      color: $white-50;
    }

    .tagContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: wrap;

      span {
        font-size: small;
        text-wrap: nowrap;
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        color: $gray-300;
        width: max-content;
        border-radius: 0.5rem;
        padding: 0.3rem 0.6rem;
        border: 1px solid $gray-500;
      }
    }

    strong {
      font-weight: 700;
      font-size: large;
      letter-spacing: 0.05rem;
      color: $orange-400;
    }
  }

  .sliderItemSummary > pre {
    font-size: 1.1rem;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    -webkit-box-orient: vertical;
    font-family: 'Segoe UI', sans-serif;

    @include responsive('md') {
      -webkit-line-clamp: 6;
    }
  }

  .sliderItemActions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    @include responsive('md') {
      justify-content: flex-end;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      a,
      button {
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        color: $white-50;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.1s linear;
        gap: 0.5rem;
      }

      &:nth-child(1) a {
        background-color: $pink-400;

        &:hover {
          background-color: $pink-500;
        }

        & > img {
          transform: rotateZ(45deg);
        }
      }

      &:nth-child(1) button:nth-child(2) {
        background-color: $orange-400;

        &:hover {
          background-color: $orange-500;
        }
      }

      &:nth-child(2) button:nth-child(1) {
        background-color: $green-600;

        &:hover {
          background-color: $green-700;
        }
      }

      &:nth-child(2) button:nth-child(2) {
        background-color: $red-300;

        &:hover {
          background-color: $red-400;
        }
      }
    }
  }
}
