@import '@/styles/variables';
@import '@/styles/breakpoints';

.footer {
  width: 100%;
  margin-top: 10rem;

  & .footerBox {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 3rem 0;

    @include responsive('sm') {
      grid-template-columns: 1fr 2fr;
      gap: 1.5rem;
    }

    @include responsive('xl') {
      grid-template-columns: 1fr 3fr;
      gap: 5rem;
      margin: 5rem 0;
    }

    & > .introduction {
      overflow: hidden;
      width: 100%;
      padding: 0 1rem;

      & > img {
        width: 100%;
        height: auto;
        max-width: 12rem;
        min-width: 10rem;
        margin-bottom: 2rem;

        @include responsive('sm') {
          max-width: 12rem;
          min-width: 10rem;
        }
      }
    }

    & > .navigationContainer {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      width: 100%;
      margin: 5rem auto 1rem;

      @include responsive('sm') {
        margin: unset;
        justify-content: flex-end;
      }

      & > .navigationLinks {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        width: 100%;

        @include responsive('lg') {
          width: 50%;
        }

        & > .navigationLink {
          display: flex;
          flex-direction: column;
          justify-content: center;

          & > h3 {
            display: inline-block;
            padding: 0;
            text-wrap: nowrap;
            font-size: medium;

            @include responsive('sm') {
              padding: 1rem;
              font-size: large;
            }
          }

          & > ul {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            margin-top: 1rem;

            @include responsive('sm') {
              margin-top: 0;
            }

            & > li {
              & > a {
                padding: 0.5rem 0;
                width: 100%;
                height: 100%;
                display: flex;
                border-radius: 0.5rem;
                transition: all 0.2s ease-in-out;
                color: $black-100;

                @include responsive('sm') {
                  padding: 0.5rem 1rem;
                }

                &:hover {
                  color: $black-50;
                }
              }
            }
          }
        }
      }
    }
  }

  & > .copyright {
    width: 100%;
    height: 100%;
    background-color: $black-700;
    padding: 0.5rem 0;

    & .copyrightBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      flex-direction: column;

      @include responsive('sm') {
        flex-direction: row;
      }

      & > span {
        font-size: medium;
        text-wrap: nowrap;
      }

      & > nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > ul {
          gap: 2rem;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;

          & > li {
            & > a {
              display: flex;
              width: 100%;
              height: 100%;
              padding: 1rem;
              color: $black-100;

              &:hover {
                color: $black-50;
              }
            }
          }
        }
      }
    }
  }
}
