@import '@/styles/variables';
@import '@/styles/breakpoints';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 3rem;
}

.profileInfo {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include responsive('xs') {
    gap: 1.75rem;
  }

  @include responsive('sm') {
    gap: 2rem;
  }

  @include responsive('md') {
    gap: 3rem;
  }

  @include responsive('lg') {
    gap: 4rem;
  }

  .profileImage {
    width: 100%;
    max-width: 25%;
    min-width: 7.5rem;
    height: auto;
    border-radius: 50%;
    background-color: aliceblue;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 1rem;

    svg {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
      font-size: 1.2rem;

      @include responsive('sm') {
        font-size: 1.8rem;
      }
    }

    .stats {
      display: flex;
      gap: 1rem;

      &:hover {
        span {
          color: $orange-50 !important;
        }
      }

      span {
        font-size: small;
        color: $white-400;
        transition: all 0.3s ease;

        @include responsive('sm') {
          font-size: medium;
        }
      }
    }

    .bio {
      font-size: medium;
      color: $white-300;
    }
  }
}

.editButton {
  display: flex;
  align-items: center;
  gap: 1rem;

  a {
    background-color: #4c4c4c;
    color: white;
    padding: 0.65rem 1rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #6f6f6f;
    }
  }
}
