@import '@/styles/variables';
@import '@/styles/breakpoints';

.recentBooks {
  margin-top: 3rem;
  padding: 1rem;
  text-align: center;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: $white-50;
  }
}
