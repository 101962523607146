@import '@/styles/variables';
@import '@/styles/breakpoints';

.section {
  margin-top: 0;

  @include responsive('sm') {
    margin-top: 3rem;
  }

  @include responsive('md') {
    margin-top: 7.5rem;
  }

  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    gap: 3rem;

    & > .cardItem {
      display: grid;
      position: relative;
      grid-template-columns: 3.5rem 1fr;
      min-height: 10rem;
      height: 100%;

      & > .cardItemSide {
        display: flex;
        flex-direction: column;
        height: calc(100% - 0.5rem);
        width: 100%;
        align-items: center;
        gap: 2rem;
        margin-top: 0.5rem;
        position: relative;

        @include responsive('lg') {
          height: calc(100% - 0.75rem);
          margin-top: 0.75rem;
        }

        & > hr {
          width: 3px;
          height: 100%;
          display: inline-block;
          border-radius: 2rem;
        }

        & > img {
          width: 100%;
          max-width: 1.5rem;
          height: auto;
          min-height: 1.5rem;
          max-height: 1.5rem;
          position: relative;
        }

        & > div:nth-child(1) {
          width: 3rem;
          height: 3rem;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          position: absolute;
          transform: translate(0, -20%);
          filter: blur(10px);

          & > div {
            background: radial-gradient(rgb(230, 100, 100), rgb(0, 0, 0));
            filter: blur(15px);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      & > .cardItemContent {
        position: relative;
        left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;
        width: calc(100% - 1rem);

        & > h2 {
          text-wrap: wrap;
          background: rgb(227, 151, 96) no-repeat;
          -webkit-background-clip: text !important;
          background-clip: text !important;
          color: transparent !important;
          font-size: x-large;

          @include responsive('lg') {
            text-wrap: nowrap;
            width: min-content;
            font-size: xx-large;
          }
        }

        & > p {
          color: $black-200;
          font-size: medium;
          letter-spacing: 0.35px;
          line-height: 1.5;

          @include responsive('md') {
            font-size: large;
          }
        }
      }
    }
  }
}
