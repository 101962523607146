@import '@/styles/variables';
@import '@/styles/breakpoints';

.section {
  margin-top: 7.5rem;

  @include responsive('md') {
    margin-top: 10rem;
  }

  .howItWorks {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;

    @include responsive('sm') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive('md') {
      gap: 2rem;
    }

    @include responsive('lg') {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      margin: auto;
    }

    &_item {
      background-color: $black-900;
      color: $white-50;
      padding: 1rem;
      border-radius: 1rem;
      border: 1px solid $gray-shadow-20;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include responsive('md') {
        padding: 2rem 3rem 5rem;
        gap: 1rem;
      }

      @include responsive('lg') {
        gap: 2rem;
      }

      &_icon {
        width: 2.75rem;
        height: auto;

        @include responsive('md') {
          width: 3rem;
          height: auto;
        }

        @include responsive('lg') {
          width: 3.5rem;
          height: auto;
        }
      }

      &_title {
        font-weight: bold;
        font-size: 1.2rem;

        @include responsive('md') {
          font-size: 1.35rem;
        }

        @include responsive('lg') {
          font-size: 1.5rem;
        }
      }

      &_description {
        font-size: 0.9rem;
        line-height: 2;

        @include responsive('md') {
          font-size: 1rem;
          letter-spacing: 0.3px;
        }

        @include responsive('lg') {
          font-size: 1.1rem;
          letter-spacing: 0.35px;
        }
      }
    }
  }
}
