@import '@/styles/variables';
@import '@/styles/breakpoints';

.navbarContainer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .navbar {
    height: 4.5rem;
    min-height: 4.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .logo {
      position: relative;
      width: 150px;
      height: 100%;
    }

    .navigationLinkContainer {
      display: flex;
      position: fixed;
      right: -200%;
      transition: all 0.4s ease;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      height: 100vh;
      z-index: 9;
      top: 0;

      @include responsive('md') {
        display: none;
      }

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 2rem;

        li {
          a {
            font-family: 'Inter', sans-serif;
            color: #ffffff;
            padding: 0.2rem 0.5rem;
            width: 100%;
            height: 100%;
            display: flex;
            font-size: x-large;
          }
        }
      }
    }

    .navigationLinks {
      gap: 0.5rem;
    }

    .actions {
      gap: 0.75rem;
    }

    .navigationLinks,
    .actions {
      list-style: none;
      height: 100%;
      margin: 0;
      padding: 0;
      display: none;

      @include responsive('md') {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      li {
        position: relative;

        a {
          font-family: 'Inter', sans-serif;
          color: $white-50;
          padding: 0.2rem 0.5rem;
          width: 100%;
          height: 100%;
          display: flex;
          font-size: x-large;
          transition: all 0.4s ease;

          @include responsive('md') {
            font-size: 1.1rem;
          }

          @include responsive('lg') {
            padding: 0.5rem 0.75rem;
          }

          &:hover {
            color: $white-400;
          }
        }

        .actionsButton {
          background-color: transparent;
          padding: 0.5rem;
          color: $white-50;
          transition: all 0.4s ease;
          font-size: larger;

          &:hover {
            color: $white-400;
          }
        }

        .profileLogo {
          position: relative;
          padding: 0.3rem 0.35rem;
          border-radius: 50%;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          svg {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: $brown-50;
              z-index: -1;
            }
          }
        }

        .profileMenu {
          display: none;

          @include responsive('md') {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            position: absolute;
            z-index: 999999999;
            top: 3.25rem;
            right: 0;
            background: black;
            padding: 2rem 2.5rem 0.5rem 1rem;
            border-radius: 0.5rem;
            border: 1px solid rgba(255, 255, 255, 0.2);
            width: max-content;
            height: max-content;
          }

          li {
            a:hover {
              color: $white-400;
            }

            &:last-child {
              margin: 1.75rem 0 0;

              a {
                color: $red-300;

                &:hover {
                  color: $red-200;
                }
              }
            }

            &.seperateLineProfileMenu {
              margin-top: 1.5rem;
              position: absolute;
              width: 100%;
              left: 0;
              right: 0;

              hr {
                border-color: $black-shadow-20;
                border-radius: 0.15rem;
              }
            }
          }
        }
      }
    }
  }
}

.menuBar {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive('md') {
    display: none;
  }

  .menuButton {
    position: relative;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;

    .line {
      width: 25px;
      height: 1px;
      background: #fff;
      border-radius: 3px;
    }
  }
}

.menuItems {
  list-style: none;
  margin: 0;
  padding: 1rem;
  background: #444;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  li {
    a {
      text-decoration: none;
      color: #fff;
      font-size: 1.2rem;
      transition: color 0.3s;

      &:hover {
        color: #f39c12;
      }
    }
  }
}
